<template>
  <div class="container px-0 z-index-1 position-relative">
    <nav class="navbar navbar-expand-xl landing-nav ps-2">
      <router-link class="navbar-brand" to="/">
        <img loading="lazy" v-if="activeTab == 'talent'" src="@/assets/images/logo/2Asset 1.svg" alt="" width="120" height="120" />
        <img loading="lazy" v-else src="@/assets/images/logo/Asset 1.svg" alt="" width="120" height="120" />
      </router-link>

      <div class="btn-group loggedin-dropdown" style="order: 2">
        <button
          v-if="activeTab == 'client'"
          type="button"
          class="ml-auto text-white btn login dark"
          @click="() => handleRedirection('/login')"
        >
          {{ $t("home.login") }}
        </button>
        <button
          v-else
          type="button"
          class="ml-auto text-white btn login"
          @click="() => handleRedirection('/login')"
        >
          {{ $t("home.login") }}
        </button>

        <LanguageSwitcher v-if="activeTab == 'client'" class="language-switcher-large" black bold />
        <LanguageSwitcher v-else class="language-switcher-large" />
      </div>
      <!--  <div class="login-btn" style="order: 3">
      
      </div> -->
      
     <LanguageSwitcher separator-light has-divider :dark-divider="activeTab == 'client'" divider-at-start :black="activeTab == 'client'" class="me-2 ms-auto lg:tw-hidden" /> 

      <button
        class="navbar-toggler"
        :class="{ light: activeTab == 'talent' }"
        type="button"
        @click="triggerMobileMenu = !triggerMobileMenu"
      >
        <!-- <span class="navbar-toggler-icon"> -->
        <svg data-v-0315f6de="" width="20px" height="23px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g data-v-0315f6de="" id="Client" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-0315f6de="" id="02.01.squadio2-final-design" transform="translate(-1462.000000, -39.000000)"><g data-v-0315f6de="" id="Group-5" transform="translate(1462.000000, 39.000000)"><rect data-v-0315f6de="" id="Rectangle" x="0" y="0" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="8" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="16" width="20" height="2" rx="1"></rect></g></g></g></svg>
        <!-- </span> -->
      </button>

       <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul
          class="mb-2 nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0"
        >
          <template v-if="activeTab == 'client'">
            <li>
              <a href="javascript:void(0)" @click="scrollToSection('meet-our-talents')" class="px-3 nav-link dark" :class="{ 'active': activeSection === 'meet-our-talents' }">
                {{ $t("home.meetOurTalents") }}</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="scrollToSection('case-studies')" class="px-3 nav-link dark" :class="{ 'active': activeSection === 'case-studies' }">
                {{ $t("home.caseStudies") }}</a
              >
            </li>
            <!-- <li>
              <a href="#podcast" class="px-3 nav-link dark">
                {{ $t("home.podcast") }}</a
              >
            </li> -->
            <li>
              <a href="javascript:void(0)" @click="scrollToSection('benefits')" class="px-3 nav-link dark" :class="{ 'active': activeSection === 'benefits' }">
                {{ $t("home.benefits") }}</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="scrollToSection('testimonials')" class="px-3 nav-link dark" :class="{ 'active': activeSection === 'testimonials' }">
                {{ $t("home.testimonials") }}</a
              >
            </li>
            <li class="language-switcher-small">
              <LanguageSwitcher class="ml-2" black bold />
            </li>
          </template>
          <template v-else>
            <li>
              <a href="javascript:void(0)" class="px-3 nav-link link-secondary">
                {{ $t("talent.talentHome") }}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="scrollToSection('bringYourBuddy')" class="px-3 nav-link" :class="{ 'active': activeSection === 'bringYourBuddy' }">
                {{ $t("talent.bringYourBuddy") }}</a
              >
            </li>
            <li class="language-switcher-small">
              <LanguageSwitcher class="ml-2" />
            </li>
          </template>
        </ul>
        <button
          type="button"
          class="nav-button btn btn-primary btn-md"
          :class="{'purple-btn': activeTab == 'client' }"
          @click="getStarted()"
        >
          {{ $t("home.getStarted") }}
        </button>
      </div>

      <mobile-menu
        :is-open="triggerMobileMenu"
        not-registered
        @close-menu="menuClosed"
        @show-register-modal="showModal = true"
      >
        <template v-if="activeTab == 'client'">
          <li class="mobileMenu__item">
            <a href="javascript:void(0)" @click="scrollToSection('meet-our-talents')" class="px-3 nav-link dark" :class="{ 'active': activeSection === 'meet-our-talents' }">
              {{ $t("home.meetOurTalents") }}</a
            >
          </li>
          <li class="mobileMenu__item">
            <a href="javascript:void(0)" @click="scrollToSection('case-studies')" class="px-3 nav-link dark" :class="{ 'active': activeSection === 'case-studies' }">
              {{ $t("home.caseStudies") }}</a
            >
          </li>
          <!-- <li class="mobileMenu__item">
            <a href="#podcast" class="px-3 nav-link dark">
               {{ $t("home.podcast") }}</a
            >
          </li> -->
          <li class="mobileMenu__item">
            <a href="javascript:void(0)" @click="scrollToSection('benefits')" class="px-3 nav-link dark" :class="{ 'active': activeSection === 'benefits' }">
              {{ $t("home.benefits") }}</a
            >
          </li>
          <li class="mobileMenu__item">
            <a href="javascript:void(0)" @click="scrollToSection('testimonials')" class="px-3 nav-link dark" :class="{ 'active': activeSection === 'testimonials' }">
              {{ $t("home.testimonials") }}</a
            >
          </li>
        </template>
        <template v-else>
          <li class="mobileMenu__item">
            <a href="javascript:void(0)">
              {{ $t("talent.talentHome") }}
            </a>
          </li>
          <li class="mobileMenu__item">
            <a href="javascript:void(0)" @click="scrollToSection('bringYourBuddy')" :class="{ 'active': activeSection === 'bringYourBuddy' }">
              {{ $t("talent.bringYourBuddy") }}</a
            >
          </li>
        </template>
      </mobile-menu>
    </nav>

    <transition name="fade" appear>
      <div v-if="showModal" class="modal">
        <div class="modal-background" @click="showModal = false"></div>
        <div class="modal-content">
          <!-- Close button positioned at top right -->
          <div class="tw-py-4 text-end">
            <button class="btn-close" @click="showModal = false"></button>
          </div>
          <router-link to="/client-signup" @click="sendTrackingEvent('client')">
            <h3>{{ $t('home.ImHiring') }}</h3>
            <p>{{ $t('home.ImHiringInfo') }}</p>
          </router-link>
          <router-link to="/talent-signup" @click="sendTrackingEvent('talent')">
            <h3>{{ $t('home.ImTalent') }}</h3>
            <p>{{ $t('home.ImTalentInfo') }}</p>
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const LanguageSwitcher = () => import("@/components/shared/languageSwitcher/languageSwitcher.vue");
const MobileMenu = () => import("@/components/MobileMenu");

// Smooth scroll polyfill for browsers that don't support scrollTo with behavior: 'smooth'
function smoothScrollPolyfill() {
  // Check if native smooth scrolling is supported
  if ('scrollBehavior' in document.documentElement.style) return;
  
  // Polyfill implementation
  const originalScrollTo = window.scrollTo;
  window.scrollTo = function() {
    if (arguments[0] === undefined) {
      return originalScrollTo.call(this, arguments);
    }
    
    let options = arguments[0];
    if (options.behavior !== 'smooth') {
      return originalScrollTo.apply(this, arguments);
    }
    
    const start = window.pageYOffset;
    const target = options.top || 0;
    const distance = target - start;
    const duration = 500; // ms
    const startTime = performance.now();
    
    function step(currentTime) {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeInOutCubic = progress < 0.5 
        ? 4 * progress * progress * progress 
        : 1 - Math.pow(-2 * progress + 2, 3) / 2;
      
      window.scrollTo(0, start + distance * easeInOutCubic);
      
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    }
    
    window.requestAnimationFrame(step);
  };
}

export default {
  props: {
    activeTab: {
      type: String,
      default: "client"
    },
  },
  components: {
    LanguageSwitcher,
    MobileMenu,
  },
  data() {
    return {
      triggerMobileMenu: false,
      showModal: false,
      activeSection: null,
      sections: ['services-section', 'meet-our-talents', 'case-studies', 'benefits', 'testimonials', 'bringYourBuddy'],
    };
  },
  mounted() {
    const body = document.querySelector('body');
    body.classList.remove('is-fixed');
    
    // Initialize smooth scroll polyfill
    smoothScrollPolyfill();
    
    // Add scroll event listener to update active section
    window.addEventListener('scroll', this.handleScroll);
    
    // Initial check for active section
    this.handleScroll();
  },
  beforeDestroy() {
    // Remove scroll event listener when component is destroyed
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Get current scroll position
      const scrollPosition = window.scrollY + 100; // Adding offset for better UX
      
      // Find the current active section
      for (const sectionId of this.sections) {
        const element = document.getElementById(sectionId);
        if (element) {
          const offset = element.offsetTop;
          const height = element.offsetHeight;
          
          // Check if the section is in view
          if (scrollPosition >= offset && scrollPosition < offset + height) {
            this.activeSection = sectionId;
            break;
          }
        }
      }
    },
    scrollToSection(sectionId) {
      // Close mobile menu if open
      if (this.triggerMobileMenu) {
        this.menuClosed();
      }
      
      // Set active section
      this.activeSection = sectionId;
      
      // Find the section element
      const element = document.getElementById(sectionId);
      if (element) {
        try {
          // Get the element's position relative to the viewport
          const elementPosition = element.getBoundingClientRect().top;
          // Get the current scroll position
          const offsetPosition = elementPosition + window.pageYOffset - 80; // Subtract header height for better positioning
          
          // Smooth scroll to the element
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        } catch (error) {
          // Fallback for browsers that don't support scrollTo with options
          this.scrollToFallback(element, 500); // 500ms duration
        }
      }
    },
    
    // Fallback smooth scroll function for older browsers
    scrollToFallback(element, duration) {
      const start = window.pageYOffset;
      const target = element.getBoundingClientRect().top + start - 80; // Subtract header height
      const startTime = performance.now();
      
      function animateScroll(currentTime) {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        
        // Easing function - easeInOutQuad
        const easing = progress < 0.5 
          ? 2 * progress * progress 
          : -1 + (4 - 2 * progress) * progress;
        
        window.scrollTo(0, start + (target - start) * easing);
        
        if (progress < 1) {
          window.requestAnimationFrame(animateScroll);
        }
      }
      
      window.requestAnimationFrame(animateScroll);
    },
    getStarted() {
      this.showModal = true
      if (window.gtag) window.gtag("event", "get started", { click_cta: 'Navbar Button' })
    },
    menuClosed() {
      this.triggerMobileMenu = false;
      const body = document.querySelector('body');
      body.classList.remove('is-fixed');
    },
    handleRedirection(path) {
      this.$router.push({ path });
    },
    sendTrackingEvent(user_type) {
      if (window.gtag) window.gtag("event", "user type selected", { user_type })
    },
  },
};
</script>


<style scoped>
.nav-button {
  border-radius: 30px;
}

.language-switcher-large {
  display: flex;
  @media (max-width: 786px) {
    display: none !important;
  }
}

.language-switcher-small {
  display: none;

  @media (max-width: 786px) {
    display: flex;
  }
}

.dark {
  color: #414042 !important;
}

.loggedin-dropdown * {
  font-weight: 600 !important;
}

/* Define the fade-in transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal a {
  width: calc(100% - 100px);
  min-width: 300px;
  margin-inline: auto;
  padding: 25px 20px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 #F6F6F6;
  transition: background-color 0.3s ease;
  border: 1px solid #E6E6E6;
}

.modal a:first-of-type {
  margin-bottom: 30px;
}

.modal h3 {
  color: #422e87;
  font-size: 28px;
  font-weight: 800;
  transition: color 0.3s ease;
}
.modal p {
  font-size: 23px;
  color: #494d55;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .modal h3 {
    font-size: 18px;
  }

  .modal p {
    font-size: 14px;
  }
}

.modal a:hover {
  background-color: #422e87;
  border-color: transparent;
}
.modal a:hover h3 {
  color: #ffc629;
}
.modal a:hover p {
  color: #fff;
}

/* Modal background styles */
.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Black background with opacity */
}

/* Modal content styles */
.modal-content {
  position: relative; /* Add relative positioning to the content container */
  background: white;
  padding: 10px 20px 30px;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: calc(100% - 40px);
  max-width: 700px;
  border-radius: 30px;
  overflow: hidden;
}

/* Add smooth scrolling for the entire page */
:root {
  scroll-behavior: smooth;
}

/* Style for active nav links */
.nav-link.active {
  font-weight: bold;
  color: #422e87 !important;
}
</style>

<style>
/* Global styles for smooth scrolling */
html {
  scroll-behavior: smooth;
}

body {
  scroll-padding-top: 80px; /* Add padding for fixed header */
}
</style>
